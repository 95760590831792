import { FormikTextarea } from 'src/components/FormFields';
import NewReportSection from '../Section';

const AssistanceProvided = ({ isDisabled = false, values, onValuesChange }) => {
  return (
    <NewReportSection
      title="Assistance Provided by Property Staff"
      onNoValue={() => {
        return !values.assitance;
      }}
    >
      <FormikTextarea
        name="assitance"
        placeholder="Assistance Provided by Property Staff"
        disabledOverlay={isDisabled}
        onChangeCb={onValuesChange}
      ></FormikTextarea>
    </NewReportSection>
  );
};

export default AssistanceProvided;
