import { FormCardContent } from 'src/components';
import { FormikTextarea } from 'src/components/FormFields';
import NewReportSection from '../Section';

const SummaryOfIncident = ({ isDisabled = false, values, onValuesChange }) => {
  return (
    <NewReportSection
      title="Summary of Incident"
      required
      onNoValue={() => {
        return !values.summary;
      }}
    >
      <FormCardContent column={1}>
        <FormikTextarea
          name="summary"
          placeholder="Summary"
          disabledOverlay={isDisabled}
          onChangeCb={onValuesChange}
        />
      </FormCardContent>
    </NewReportSection>
  );
};

export default SummaryOfIncident;
