import cn from 'classnames';
import {
  Checkbox,
  FormikTextarea,
  FormikUpload,
} from 'src/components/FormFields';
import NewReportSection from '../Section';
import classes from './PhotoDocumentUpload.module.scss';

const PhotoDocumentUpload = ({
  isDisabled = false,
  values,
  setFieldValue,
  requiredChecks = null,
  onFilesChanged = null,
  onFilesRemoved = null,
  uploading = null,
  onValuesChange,
}) => {
  return (
    <div className={classes.wrapper}>
      <NewReportSection
        title="Photos"
        required={values.photos_required}
        className={cn(classes.block, {
          [classes.error]: requiredChecks?.photos,
        })}
        onNoValue={() => {
          return (
            !values.photos_additional_information &&
            !values.photos_additional_upload?.length
          );
        }}
      >
        <FormikTextarea
          name="photos_additional_information"
          placeholder="Additional Information"
          disabledOverlay={isDisabled}
          onChangeCb={onValuesChange}
        ></FormikTextarea>
        <div className={classes.uploadWrapper}>
          <FormikUpload
            name="photos_additional_upload"
            multiple={true}
            onFileRemoved={(_, index) => {
              if (onFilesRemoved) onFilesRemoved(index, 'photos');
            }}
            printBtnText="Upload files:"
            className={cn({
              [classes.uploadFiles]: !!values.photos_additional_information,
            })}
            onFilesChanged={(tFiles) => {
              if (onFilesChanged) onFilesChanged(tFiles, 'photos');
            }}
            loading={uploading?.photos}
            disabled={!!values.photos_additional_upload_na || isDisabled}
          />
          <Checkbox
            checked={values.photos_additional_upload_na}
            onChange={(e) => {
              setFieldValue('photos_additional_upload_na', e.target.checked);
              onValuesChange();
            }}
            pointer
            radius="xs"
            className={cn(classes.checkbox, {
              [classes.isDisabled]:
                values.photos_additional_upload?.length > 0 || isDisabled,
            })}
            label="N/A"
            disabled={values.photos_additional_upload?.length > 0 || isDisabled}
          />
        </div>
      </NewReportSection>
      <NewReportSection
        title="Witness Statements"
        required={values.witness_required}
        className={cn(classes.block, {
          [classes.error]: requiredChecks?.witness,
        })}
        onNoValue={() => {
          return (
            !values.witness_additional_information &&
            values.witness_additional_upload?.length
          );
        }}
      >
        <FormikTextarea
          name="witness_additional_information"
          placeholder="Additional Information"
          disabledOverlay={isDisabled}
          onChangeCb={onValuesChange}
        ></FormikTextarea>
        <div className={classes.uploadWrapper}>
          <FormikUpload
            name="witness_additional_upload"
            multiple={true}
            onFileRemoved={(_, index) => {
              if (onFilesRemoved) onFilesRemoved(index, 'witness_statements');
            }}
            printBtnText="Upload files:"
            className={cn({
              [classes.uploadFiles]: !!values.witness_additional_information,
            })}
            onFilesChanged={(tFiles) => {
              if (onFilesChanged) onFilesChanged(tFiles, 'witness_statements');
            }}
            loading={uploading?.witness_statements}
            disabled={!!values.witness_additional_upload_na || isDisabled}
          />
          <Checkbox
            checked={values.witness_additional_upload_na}
            onChange={(e) => {
              setFieldValue('witness_additional_upload_na', e.target.checked);
              onValuesChange();
            }}
            pointer
            radius="xs"
            className={cn(classes.checkbox, {
              [classes.isDisabled]:
                values.witness_additional_upload?.length > 0 || isDisabled,
            })}
            label="N/A"
            disabled={
              values.witness_additional_upload?.length > 0 || isDisabled
            }
          />
        </div>
      </NewReportSection>
      <NewReportSection
        title="Other Additional Documents"
        required={values.related_documents_required}
        className={cn(classes.block, {
          [classes.error]: requiredChecks.related_documents,
        })}
        onNoValue={() => {
          return (
            !values.related_documents_additional_information &&
            values.related_documents_additional_upload?.length
          );
        }}
      >
        <FormikTextarea
          name="related_documents_additional_information"
          placeholder="Additional Information"
          disabledOverlay={isDisabled}
          onChangeCb={onValuesChange}
        ></FormikTextarea>
        <div className={classes.uploadWrapper}>
          <FormikUpload
            name="related_documents_additional_upload"
            multiple={true}
            onFileRemoved={(_, index) => {
              if (onFilesRemoved)
                onFilesRemoved(index, 'other_additional_documents');
            }}
            printBtnText="Upload files:"
            className={cn({
              [classes.uploadFiles]:
                !!values.related_documents_additional_information,
            })}
            onFilesChanged={(tFiles) => {
              if (onFilesChanged)
                onFilesChanged(tFiles, 'other_additional_documents');
            }}
            loading={uploading?.other_additional_documents}
            disabled={
              !!values.related_documents_additional_upload_na || isDisabled
            }
          />
          <Checkbox
            checked={values.related_documents_additional_upload_na}
            onChange={(e) => {
              setFieldValue(
                'related_documents_additional_upload_na',
                e.target.checked
              );
              onValuesChange();
            }}
            pointer
            radius="xs"
            className={cn(classes.checkbox, {
              [classes.isDisabled]:
                values.related_documents_additional_upload?.length > 0 ||
                isDisabled,
            })}
            label="N/A"
            disabled={
              values.related_documents_additional_upload?.length > 0 ||
              isDisabled
            }
          />
        </div>
      </NewReportSection>
    </div>
  );
};

export default PhotoDocumentUpload;
